import React, { useEffect } from "react";
import ImageSlider from "./ImageSlider";

import hun from "./Images/100 Natural Image 1.png";
import fed from "./Images/heroimg.png";
import first from "./Images/Frame24.png";
import second from "./Images/Frame25.png";
import third from "./Images/Frame26.png";
import one from "./Images/ourProduct/new/pngwing 2.png";
import two from "./Images/ourProduct/new/pngwing 4.png";
import three from "./Images/ourProduct/new/cashew nut in basket 1.png";
import four from "./Images/ourProduct/new/pngwing 6.png";
import five from "./Images/ourProduct/new/pngwing 7.png";
import six from "./Images/ourProduct/new/images-removebg-preview 1.png";
import seven from "./Images/ourProduct/new/pngwing 8.png";
import eight from "./Images/ourProduct/new/pngwing 9.png";
import nine from "./Images/ourProduct/new/orange in basket.png";
import ten from "./Images/ourProduct/new/pngwing 10.png";
import eleven from "./Images/ourProduct/new/360_F_279375784_vXsCIlhlDoqNX0vj9IZV1nuvbapa4dJp-removebg-preview 1.png";
import twelve from "./Images/ourProduct/new/cassia-tora-seed-1-removebg-preview 1.png";
import thirteen from "./Images/ourProduct/new/images-3-removebg-preview 1.png";
import fourteen from "./Images/ourProduct/new/XMORL-removebg-preview 1.png";
import fifteen from "./Images/ourProduct/new/fresh-turmeric-roots-in-the-basket-on-wooden-table-free-photo-removebg-preview 1.png";
import inye from "./Images/ImageSlider/new/Image.png";
import ehgi from "./Images/ImageSlider/new/Group 3.png";
import ehta from "./Images/ImageSlider/new/Group 2.png";
import eleh from "./Images/ImageSlider/new/Group 4.png";
import eluh from "./Images/ImageSlider/new/Peanut.png";
import efa from "./Images/ImageSlider/new/tigernut.png";
import ehbie from "./Images/ImageSlider/new/Group 1.png";
import ehjor from "./Images/ImageSlider/ehjor.png";
import ela from "./Images/ImageSlider/new/ela.png";
import ehgwa from "./Images/ImageSlider/ehgwa.png";
import pigen from "./Images/ImageSlider/new/Pigeon-peas 1.png";
import cassa from "./Images/ImageSlider/new/food3049311605251519831550-removebg-preview 1.png";
import gin from "./Images/ImageSlider/new/pngwing 11.png";
import green from "./Images/ImageSlider/new/moringa-dried-leaves-500x500 2.png";
import tum from "./Images/ImageSlider//new/Grow-Turmeric 1.png";

import "./App.css";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const ourProduct = [
    {
      image: one,
      title: "Dried Hibiscus Whole Flower",
      text: `is used differently in many countries for tea, medicinal purposes, animal fodder, etc. Due to its diverse use, the dried hibiscus flower, known in Nigeria as Zobo.`,
      id: 1,
    },
    {
      image: two,
      title: "Sesame Seeds",
      text: `is widely naturalized in tropical regions around the world and is cultivated for its edible seeds, which grow in pods. World production in 2018 was 6 million metric.`,
      id: 2,
    },
    {
      image: three,
      title: "Cashew Nuts",
      text: `it’s seed yields derivative that can be used in many applications including lubricants, waterproofing, paints, and, starting in 
    World War II, arms production. `,
      id: 3,
    },
    {
      image: four,
      title: "Ginger (Fresh & Dry)",
      text: `is a flowering plant whose rhizome, ginger root or ginger, is widely used as a spice and a folk medicine. It is a herbaceous perennial that grows annual pseudostemsl`,
      id: 1,
    },
    {
      image: five,
      title: "Peanut",
      text: `is a legume crop grown mainly for its edible seeds. It is widely grown in the tropics and subtropics, important to both small and large commercial producers.`,
      id: 1,
    },
    {
      image: six,
      title: "Tiger Nuts",
      text: `is a species of plant in the sedge family widespread across much of the world. It is found in most of the Eastern Hemisphere, including Southern Europe, Africa etc.`,
      id: 1,
    },
    {
      image: seven,
      title: "Shea Butter/Nut",
      text: `is a fat extracted from the nut of the African shea tree. It is ivory in colour when raw and commonly dyed yellow with borututu root or palm oil. `,
      id: 1,
    },
    {
      image: eight,
      title: "Soy Beans",
      text: `Is a species of legume native to East Asia, widely grown for its edible bean, which has numerous uses. Traditional unfermented food uses of soybeans include soy milk.`,
      id: 1,
    },
    {
      image: nine,
      title: "Orange",
      text: `Is a fruit of various citrus species in the fa-mily Rutaceae.It is primarily refers to Citrus × sinensis, which is also called sweet orange, distinguish it from the related Citrus × aura`,
      id: 1,
    },
    {
      image: ten,
      title: "Red Kidney Beans",
      text: `It contains relatively high amounts of phytohemagglutinin, and thus are more toxic than most other bean varieties if not pre-soaked and subsequently heated.`,
      id: 1,
    },

    {
      image: eleven,
      title: "Pigeon Pea",
      text: `Is a perennial legume from the family Fabaceae native to the Old World. The pigeon pea is widely cultivated in tropical and semitropical regions around the world.`,
      id: 1,
    },
    {
      image: twelve,
      title: "Cassia Tora Seeds",
      text: `Widely used due to its various biological properties including anticancer, antidia-betic, and anti-inflammatory effects. However, there has been no report of it. `,
      id: 1,
    },
    {
      image: thirteen,
      title: "Fresh Garlic",
      text: `Is a species of bulbous flowering plant in the genus Allium.Its close relatives include the onion, shallot, leek, chive, Welsh onion and Chinese onion.It is native to South Asia, `,
      id: 1,
    },
    {
      image: fourteen,
      title: "Dried Moringa Leaves",
      text: `Widely cultivated for its young seed pods and leaves, used as vegetables and for traditional herbal medicine. It is also used for water purification.`,
      id: 1,
    },
    {
      image: fifteen,
      title: "Turmeric",
      text: `is a flowering plant, Curcuma longa of the ginger family, Zingiberaceae, the rhizomes of which are used in cooking.The plant is a perennial, rhizomatous, herbaceous plant.`,
      id: 1,
    },
  ];

  const belowHero = [
    {
      image: first,
      text: "Global brand in Agricultural value chain",
    },
    {
      image: second,
      text: "Goods and services are at affordable price",
    },
    {
      image: third,
      text: "Respect for culture and differences",
    },
  ];

  const marg = "margin-top: -32px";

  const belowHeroo = belowHero.map((items) => (
    <div id="belowHeroodiv">
      <img id="belowHeroo" src={items.image} alt="" />
      <p id="belowHerootext">{items.text}</p>
    </div>
  ));

  const product = ourProduct.map((items, idx) => (
    <div
      key={idx}
      className={idx % 3 == 0 ? "produce" : "product"}
      id="product"
    >
      <div className="productimgdiv">
        {" "}
        <img id="productimg" src={items.image} alt="" />
      </div>

      <div className="toadj">
        <h4 id="producttitle">{items.title}</h4>
        <p id="producttext">{items.text}</p>
      </div>
    </div>
  ));

  return (
    <body>
      <div className="">
        <div className="wrapper">
          <div id="hero" className="sm:40 bg-red ">
            <div className="divimg">
              <img src={hun} id="first" alt=" imgae " />
            </div>

            <div id="nextoimg">
              <div className="holdindhed">
                <h3 id="heads">
                  Adding Value <br /> to the <span>Agro Value </span> Chain !!!
                </h3>
              </div>
              <p id="para">
                Delivering high quality products with quality services
              </p>
              <button id="butt">
                <a href="/contactus"> Get in Touch</a>
              </button>
            </div>
          </div>
        </div>
        <div className="wrapperingHero">
          <div className="wrapper">
            <h3 id="why"> Why Chuksprime Organics ?</h3>
            <p id="wyp">
              We offer premium quality and cost-effective products that reward
              clients’ requests.
            </p>

            <div id="felee">
              {" "}
              <img src={fed} alt="" id="first2" />
            </div>
          </div>
        </div>

        <div className="wrapper">
          <div id="holdingbelowHeroodiv">{belowHeroo}</div>

          <div id="productheader">
            <h3 id="why" className="orpr" style={{ marg }}>
              Our Products
            </h3>
            <p id="wyp">We sell high-quality natural and organic commodities</p>
          </div>

          <ImageSlider
            images={[
              inye,
              ehgi,
              ehta,
              eleh,
              eluh,
              efa,
              ehbie,
              ehjor,
              ela,
              ehgwa,
              pigen,
              cassa,
              gin,
              green,
              tum,
            ]}
            text={[
              "Dried Hibiscus Whole Flower",
              "Sesame Seeds",
              "Cashew Nuts",
              "Ginger (Fresh & Dry)",
              "Peanut",
              "Tiger Nuts",
              "Shea Butter/Nut",
              "Soy Beans",
              "Orange",
              "Red Kidney Beans",
              "Pigeon Pea",
              "Cassia Tora Seeds",
              "Fresh Garlic",
              "Dried Moringa Leaves",
              "Turmeric",
            ]}
          />
        </div>

        <div className="holdingwrapperproduct">
          <div className="wrapper">
            <div id="holdingproduct">{product}</div>
          </div>
        </div>
      </div>
    </body>
  );
};

export default Home;
