import React, { useState } from 'react';

import './VideoSlider.css';

import vid1 from './Images/Vid2.mp4';

import vid2 from './Images/Vid1.mp4';



const VideoSlider = () => {
  const videoPaths = [
    vid1,
    vid2,
   
  ];

  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const handleNextVideo = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videoPaths.length);
  };

  const handlePreviousVideo = () => {
    setCurrentVideoIndex((prevIndex) =>
      prevIndex === 0 ? videoPaths.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className='wrapper'>
      
      <div className="video-slider">

      <button className='onnext' onClick={handleNextVideo}><i className="fa fa-angle-left" aria-hidden="true"></i></button>

        <div className='videodiv'>
        <video className='vidww' controls src={videoPaths[currentVideoIndex]}  />

        </div>

        <button className='onnext' onClick={handlePreviousVideo}><i className='fa fa-angle-right'></i></button>
      </div>
     
    </div>
  );
};

export default VideoSlider;

