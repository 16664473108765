import React, { useState } from 'react'
import chucks from './Images/ChuksprimeLogo.svg'
import { NavLink } from 'react-router-dom';




 const Navbar = () => {
    const [state, setState]= useState(false)
    const handleClick = () => {
        setState(!state)
        
    }

    const activeStyle = {
        color: '#20713A',
        borderBottom: '2px solid #20713A',
      };

    return(
        
        <nav>
            

            <div className="brandnew">
            <a href="/">
                <div><img src={chucks} alt="" /></div>                
            </a>

            <div id='navlist' className={state ? "#navlist active" :"#navlist"} >
                <ul id="navlist1">


                    

                
					<li style={{ margin: '10px' }}>
                    <NavLink className='navlink'
                        to="/"
                        isActive={(match, location) => {
                            return match && match.isExact;
                        }}
                        style={({ isActive }) => ({
                            color: isActive ? activeStyle.color : 'black',
                            borderBottom: isActive ? activeStyle.borderBottom : 'none',
                            paddingBottom: isActive ? '6px' : '0px'
                        })}
                        >
                        Home
                        </NavLink>
					</li>

					<li style={{ margin: '10px' }}>
                    <NavLink
                        className='navlink'
                        to="/ouproduct"
                        isActive={(match, location) => {
                            return match && match.isExact;
                        }}
                        style={({ isActive }) => ({
                            color: isActive ? activeStyle.color : 'black',
                            borderBottom: isActive ? activeStyle.borderBottom : 'none',
                            paddingBottom: isActive ? '6px' : '0px'
                        })}
                        >
                        Our Product 
                        </NavLink>
					</li>
					
                    <li>
                    <NavLink
                        className='navlink'
                        to="/about"
                        isActive={(match, location) => {
                            return match && match.isExact;
                        }}
                        style={({ isActive }) => ({
                            color: isActive ? activeStyle.color : 'black',
                            borderBottom: isActive ? activeStyle.borderBottom : 'none',
                            paddingBottom: isActive ? '6px' : '0px'
                        })}
                        >
                        About Us
                        </NavLink>
                    </li>

                    <li>
                    <NavLink
                        className='navlink'
                        to="/ourgallery"
                        isActive={(match, location) => {
                            return match && match.isExact;
                        }}
                        style={({ isActive }) => ({
                            color: isActive ? activeStyle.color : 'black',
                            borderBottom: isActive ? activeStyle.borderBottom : 'none',
                            paddingBottom: isActive ? '6px' : '0px'
                        })}
                        >
                        <span> Our Gallery</span> 
                        </NavLink>
                    </li>  


                    <div  className='moilebutton'>
                    <NavLink   to="/contactus" style={({ isActive }) => ({
							color: isActive ? 'black' : 'white' })}>
							<span>Contact Us</span> 
						</NavLink>
                    </div>
                    
                                 
				
                                                   
                </ul>

               

                
            </div>


            <button  ><NavLink  onClick={() => handleClick()} to="/contactus" style={({ isActive }) => ({
							color: isActive ? 'black' : 'white' })}>
							Contact Us 
						</NavLink> </button> 

            </div>

            <div id='mobile' onClick={() => handleClick()}>
                <i id="bar" className={ state ? 'fas fa-times': 'fas fa-bars'} ></i>
                
            </div>
        </nav>
    
    )
}

export default Navbar