import React, {useEffect} from 'react'

import visone from './Images/About/smartafri 2.png'
import vistwo from './Images/About/Group 20.png'
import  './AboutUs.css'


const AboutUs = () => {

   
  useEffect(() => {
    window.scrollTo(0, 0);
  },);

  return (
    <div className='wrapper'>
      <div id='div'>
        <h3>Who Are We</h3>
        <p>Chuksprime Organics is a leading supplier and exporter of premium-grade natural and  organic agro-commodities such as dried Hibiscus Flower, Soybeans, Turmeric, Garlic, Ginger, and more, all sourced from Africa. Our natural and organic products have been serving the beverage and manufacturing industries worldwide for years. We are registered with the US Food and Drug Administration (FDA) with registration number 10633149942 and Duns and Bradsheet with registration number 851 734 820.
        
        </p>

        <p>

We take pride in offering original products with the right purity and content, free from foreign matter, and customized to meet our clients' specifications. Our vast network of farms and aggregators enable us to process and export our products at competitive market prices.
</p>

<p>
Our team of specialists ensures quality control at every stage of processing, from sorting to packaging, labelling, and shipping. We subject products to regulatory validations, such as phytosanitary tests where applicable, before delivery.
</p>

<p>
We are committed to maintaining our unmatched reputation in the industry by meeting our clients' expectations globally. We are committed to the values of relationships, integrity, supporting stakeholders, and ensuring safe practices for sustainability.
</p>
      </div>

      <div className='div'> 
          <div className="peace">
            <div className='divoo'>
          <h3>Our Mission & Vision</h3>
          
          <ul>
            <li> <strong>MISSION</strong> <p>To provide high-quality agro-commodities to our customers while supporting local communities for sustainable growth.</p> </li>
            <li> <strong>VISSION</strong><p>To be a global brand in the agro-commodities value chain with value addition</p> </li>

            <p></p>

          </ul>
          </div>
          <div className='phwwwww'>
            <img src={visone} alt="" />
          </div>
          </div>
      </div>
      <div className='div'>
      <div className='hope'>
        
        <div className='divoo' id='divoo'>
          <h3>Our Core Values</h3>
          
          <ul>
            <li>Integrity For Prosperity.</li>
            <li>Professionalism for Growth.</li>
            <li>Value-centricity For Appeal.</li>
            <li>Collaboration For Service.</li>
          </ul>
          </div>
          <div>
            <img className='imgwgwgw' src={vistwo} alt="" />
          </div>
          </div>
      </div>
    </div>
  )
}

export default AboutUs
