import React from 'react'

import footerimg from './Images/footerImages/logo.svg'
import message from './Images/footerImages/message.png'
import call from './Images/footerImages/call.png'
import twitter from './Images/footerImages/twitter.png'
import facebook from './Images/footerImages/facebook.png'
import linked from './Images/footerImages/linked.png'
import insta from './Images/footerImages/instagram.png'

import './Footer.css'
import './App.css';

const Footer = () => {

  return (
    
    <div id='footer'>

      <div className='wrapper'id='wrapper'>

        <div id='eachdiv'>

          <img id='img' src={footerimg} alt="" />
          <p id='imgpara'>An innovative company that is well equipped to produce, process and supply healthy organic and natural agro products</p>
          <div id='icons'>
            <a href="https://twitter.com"> <img src={twitter} alt="" /> </a>
            <a href="https://www.linkedin.com/company/chuksprime/"> <img src={linked}alt="" /> </a>
            <a href="https://www.facebook.com/chuksprime"> <img src={facebook} alt="" /> </a>
            <a href="https://www.instagram.com/chuksprime/"> <img src={insta} alt="" /> </a>
          </div>

        </div>

        <div id='flety' >

        <div id='eachdiv'>
          <h3>Company</h3>
          <ul id='eleven'>
            <li> <a href="">Privacy Policy</a></li>
            <li> <a href="">Terms & Conditions</a> </li>
          </ul>
        </div>

        <div id='eachdiv' >
          <h3 className='contactingus'>Contact Us</h3>
          <div id='flexy'>
            <img id='contact' src={call} alt="" />
            <ul id='eleven'>
              <li> <a href='tel:+2349137160848' className='who'>+2349137160848</a> </li>
              <li> <a href='tel:+2349131573923' className='who'>+2349131573923</a> </li>
              <li> <a href='tel:+447441426012' className='who'>+447441426012</a> </li>              
            </ul>
          </div>
          <div id='flexy'>
            <img id='contact' src={message} alt="" />
            <ul id='eleven'>
              <li> <a href="mailto:chuksprimeltd@gmail.com" className='who'>chuksprimeltd@gmail.com</a> </li>
              <li> <a href="mailto:info@chuksprimeorganics.com" className='who'>info@chuksprimeorganics.com</a> </li>
                 
            </ul>
          </div> 
        </div>
        <div id='eachdiv'>
            <h3>Website Links</h3>

            <ul id='eleven'>
              <li><a href="/">Home</a></li>
              <li><a href="/ouproduct">Our Products</a></li>
              <li><a href="/about">About Us</a></li>
              <li><a href="/contactus">Contact Us</a></li>
              <li><a href="/ourgallery">Our Gallery</a></li>
            </ul>

        </div>

        <div id='eachdiv'>
            <h3>Location</h3>
            <p id='fourteen'>Lanre Shittu House, 3rd
            Floor. Plot 1504 Cadzone
            BO6, Expressway, opposite 
            Civil Service Club, Mabushi 
            900108, Abuja, Nigeria.</p>
            <p id='fourteen'>
            28 Kingshold Road,
            London, England, E97JF.
            </p>
        </div>

       </div>  
       <div id='iconsi'>
            <a href="https://twitter.com"> <img src={twitter} alt="" /> </a>
            <a href="https://www.linkedin.com/company/chuksprime/"> <img src={linked}alt="" /> </a>
            <a href="https://www.facebook.com/chuksprime"> <img src={facebook} alt="" /> </a>
            <a href="https://www.instagram.com/chuksprime/"> <img src={insta} alt="" /> </a>
        </div> 
        

        <a
        href="https://wa.me/2349033742923"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
       
      </a>
      </div>
        <p id='copyright'>© 2023 Chuksprimeorganics. All rights reserved. </p>
      </div>
      
      
    
  )
}

export default Footer
