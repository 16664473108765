import React, {useEffect} from 'react'

import VideoSlider from './VideoSlider';

import './OurGallery.css';










import img1 from './Images/OurGallery/one.png'
import img2 from './Images/OurGallery/two.png'
import img3 from './Images/OurGallery/three.png'
import img4 from './Images/OurGallery/four.png'
import img5 from './Images/OurGallery/five.png'
import img6 from './Images/OurGallery/six.png'
import img7 from './Images/OurGallery/seven.png'
import img8 from './Images/OurGallery/eight.png'
import img9 from './Images/OurGallery/nine.png'
import img10 from './Images/OurGallery/ten.png'
import img11 from './Images/OurGallery/eleven.png'
import img12 from './Images/OurGallery/twelve.png'
import img13 from './Images/OurGallery/thirteen.png'
import img14 from './Images/OurGallery/fourteen.png'
import img15 from './Images/OurGallery/fifteen.png'
import img16 from './Images/OurGallery/sixteen.png'
import img17 from './Images/OurGallery/seventeen.png'
import img18 from './Images/OurGallery/eighteen.png'
import img19 from './Images/OurGallery/nineteen.png'
import img20 from './Images/OurGallery/twenty.png'





 const images=[ img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, img20]

 const gallery= images.map((img)=>{
 return(
                <div > 
                    <img  className='GaleryImg' src={img} alt="" />
                </div>
 )
}
    )


    


const OurGallery = () => {

   
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className='wrapper' >

     

        <h1 className='dis'>Discover Amazing Things We Do.</h1>
        <p className='peeeeeee'>We add meaning to life </p>

        <VideoSlider/>
      

        <div className='Reveal'>

        <h1 className='dis'>Revealing the World's Hidden Marvels.</h1>
        <p className='peeeeeee'>The beautiful captivating moment we had. </p>
        
      </div>  
        

        <div className='Gallery'>
            {gallery}
        </div>

        
    </div>
  )
}

export default OurGallery
