// import './App.css';
// import Home from './Home';
// import Navbar from './Navbar';
// import Footer from './Footer';
// import AboutUs from './AboutUs';
// import OurProduct from './OurProduct';
// import ContactUs from './ContactUs';
// import OurGallery from './OurGallery';

// import { BrowserRouter, Route, Routes } from 'react-router-dom';
// // import { Routes } from 'react-router-dom';

// function App() {
//   // const {pathname} = useLocation()
//   // console.log(pathname)

 


//   return (
//     <>
//       <Navbar/>
//       <BrowserRouter>
//       <Routes>
//           <Route pathname="/" exact component={Home} />
//           <Route pathname="/our-product" Component={OurProduct} />
//           <Route pathname="/about-us" component={AboutUs} />
//           <Route pathname="/our-gallery" component={OurGallery} />
//           <Route  pathname="/contact-us" component={ContactUs} />
//       </Routes>
//       </BrowserRouter>
//       <Footer/>
//     </>
//   )
// }

// export default App; 


import React from "react";
import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";
import Home from "./Home";
import AboutUs from './AboutUs';
import OurGallery from './OurGallery';
import Navbar from "./Navbar";
import Footer from './Footer';
import OurProduct from './OurProduct';
import ContactUs from './ContactUs';



function App() {

	return (
		<>
			<BrowserRouter>
        <Navbar/>
				<Routes>
					<Route exact path="/" element={<Home />} />
					<Route exact path="/about" element={<AboutUs/>} />
					<Route exact path="/ourgallery" element={<OurGallery/>} />
					<Route exact path="/ouproduct" element={<OurProduct/>} />
					<Route exact path="/contactus" element={<ContactUs/>} />
				</Routes>
        <Footer/>
			</BrowserRouter>
		</>
	);
}

export default App;


