
import React, { useState } from 'react';

import './ImageSlider.css';


const ImageSlider = ({ images, text }) => {
  
  const feddd="font-size:24px"


  const [currentImage, setCurrentImage] = useState(0);
  const [currentext, setCurrentext] = useState(0);

  const nextImage = () => {
    setCurrentImage(currentImage === images.length - 1 ? 0 : currentImage + 1);
    setCurrentext(currentext === text.length - 1 ? 0 : currentext + 1);
  };

  const prevImage = () => {
    setCurrentImage(currentImage === 0 ? images.length - 1 : currentImage - 1);
    setCurrentext(currentext === 0 ? text.length - 1 : currentext - 1);
  };

  return (
    <div className="slider">
      <button  className='btn' onClick={prevImage}><i className="fa fa-angle-left" aria-hidden="true"></i></button>
      <div id='imagetext'>
        <div className='imagdiv'>
        <img className='imag' src={images[currentImage]} alt="change" /> 
        </div>
           
      <p className='pooh'> { text[currentext]}</p>
      </div>
      <button className='btn' onClick={nextImage}><i className='fa fa-angle-right'></i></button>
    </div>
  );
};

export default ImageSlider;



