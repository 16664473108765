import React, {useEffect} from 'react'

import './contactUs.css'

const ContactUs = () => {

   useEffect(() => {
    window.scrollTo(0, 0);
  });
  
  return (
    <div className='wrapper'>
      <h2 className='cont'>Get In Touch With Us</h2>
      <p className='pee'>We will like to hear from you !!!</p>

      <form className='form'    action="https://formspree.io/f/mzblpqog"  method="POST" >
        <div className='main'> 
        <label htmlFor="">Full Name <br />
        <input name=' fullname'  required type="text" />
        </label> <br />
         
        <label htmlFor="">Email <br />
        <input  name="email" required type="email" />
        </label> <br />
         
        <label htmlFor="">Phone Number <br />
        <input name="number"  required type="text" />
        </label><br />
        
        <label htmlFor="">Subject <br />
        <input  name="subject" required type="text" />
        </label><br />
        
        <label htmlFor="">Message <br />
        <textarea name="message" id="" cols="30" rows="10"></textarea>
        </label> <br />
        </div>

        <button type='submit' id='submit'>Submit</button>

      </form>


    </div>
    
  )
}

export default ContactUs
