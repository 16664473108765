import React, {useEffect} from 'react'

import './OurProduct.css'

import daya from './Images/productpage/Daya.png'
import bui from './Images/productpage/bui.png'
import uku from './Images/productpage/uku.png'
import hudu from './Images/productpage/hudu.png'
import biyar from './Images/productpage/biyar.png'
import shida from './Images/productpage/shida.png'
import bauiwe from './Images/productpage/bauiwe.png'
import tawas from './Images/productpage/tawas.png'
import tara from './Images/productpage/tara.png'
import goma from './Images/productpage/goma.png'
import shadaya from './Images/productpage/shadaya.png'
import shabui from './Images/productpage/shabui.png'
import shauku from './Images/productpage/shauku.png'
import shahudu from './Images/productpage/shahudu.png'
import shabiya from './Images/productpage/shabiya.png'


const OurProduct = () => {

   
  useEffect(() => {
    window.scrollTo(0, 0);
  });


  return (
    <div>
      <div className="wrapper">

        <h3 id='header'>Our Product</h3>
        <p id='headerp'>We supply high quality natural and organics agro-products</p>

        <div id='prodceveeve'>

          <div className='centering'>
          
            <div className='propro'>
              
              <img id='onefr' src={daya} alt="" />
              <div className='fourhun'>
              <h3  className='txt'>Dried Hibiscus Whole Flower</h3>
              <p>We process varieties that are unique for their rich colours and quality. Good reddish scarlet dried flower. Less 8% moisture content. Free from foreign matter. Less 1.5% insoluble ash. Free from salmonella. Hand-picked flowers packed in 25/50kg PP bag or as desired by the customer. 
              </p>
              
              <p>
               We have shipped metric tonnes to the United States and various client destinations and are opened to business worldwide.
              </p>
              
              </div>
          </div>

        </div>


        <div className='proop'>
            
            <img id='onefr' src={bui} alt="" />
            <div className='fourhun'>
            <h3  className='txt'>Sesame Seeds</h3>
            <p>We supply sesame seeds with the below specification and can supply on-demand to meet customer’s quantity and specifications. <br />

              <p>
              <div className='grid'> <div><strong>  NAME: </strong>Natural White Sesame Seeds.</div>  <div><strong>ORIGIN </strong>Taraba State and parts<br /></div>   </div>     
              <div className='grid'> <div><strong> PURITY </strong>: 99% (99% min)  </div>  <div><strong>MIOSTURE CONTENT</strong>: 10 - 11.5%<br /></div>  </div>                                
              <div className='grid'> <div><strong>OIL CONTENT </strong>: 50 - 52%</div>  <div><strong>FREE FATTY ACID</strong>: 2.5% <br /></div>  </div>  

              </p>                               
              <strong>PACKAGING </strong> 50kg polypropylene bag.<br />
            </p>
            
            </div>
        </div>


        
        <div className='propro'>
            
            <img id='onefr' src={uku} alt="" />
            <div className='fourhun'>
            <h3  className='txt'>Cashew Nuts</h3>
            <p>Our Cashew Nuts (raw) are of high-quality whole nuts with a nut count of 180-200 per kg, A moisture content of 8-10% max. Defective nuts that are less than 15% and a float rate of 18% max.  Less than 0.5 foreign matter. We pack in jute bags, standardized to buyer’s preference. Clients’ specifications are binding. </p>
            
            </div>
        </div>


        <div className='proop'>
          
            
            <img id='onefr' src={hudu} alt="" />
            <div className='fourhun'>
            <h3  className='txt'>Ginger (Fresh & Dry)</h3>
            <p>Nigeria has the best ginger in the world with it’s spicy and flavoured brand. Our ginger is well-processed with zero impurities and appropriate moisture content. <br />
            Desired oleoresin oil content and nil microbes. Our ginger has pungency, is processed in hygienic conditions, with adherence to safety standards in the entire value chain. We offer competitive pricing. 

            </p> 

            <p>
            We have organically certified and natural ginger. As one of the major exporters. We are at an advantage to supply thousands of metric tons. Chuksprime has exported globally to satisfied clients.
            </p>
            
            </div>
        </div>


        
        <div className='propro'>
            
            <img id='onefr' src={biyar} alt="" />
            <div className='fourhun'>
            <h3  className='txt'>Peanut</h3>
            <p> We process our peanuts in clean, hygienic conditions and pack them in jute bags to the specifications of the buyers. They are bold, whole and with 0.5% of foreign matter. We supply nuts with 42-48% oil content, 7-8% moisture content, and moisture content and aflatoxin that is negative and an admixture of 1% max total.
            </p>
            
            </div>
        </div>


        <div className='proop'>
            
            <img id='onefr' src={shida} alt="" />
            <div className='fourhun'>
            <h3  className='txt'>Tiger Nuts</h3>
            <p> SPECIFICATIONS:</p>

            <p>
            <div className='grid'> <div> <strong>PHYSICAL:</strong>   Based on buyer’s specifications.</div> <div><strong>IMPURITIES:</strong>  Max 2% <br /></div></div> 
            <div className='grid'> <div> <strong>MOISTURE CONTENT:</strong>  Max 10% </div> <div><strong>FUNGUS:</strong>  Nil <br /></div> </div>  
            <div className='grid'> <div><strong>SIZE:</strong>  8mm to 16mm </div> <div><strong>STYLE:</strong>  Dried  <br /></div></div> 
            <div className='grid'> <div><strong>FLAVOUR:</strong>  Normal </div> <div><strong>ODOUR:</strong>  Normal </div></div>   
            Free From Preservatives. <br />
            <strong>PACKAGING:</strong>  25/50kg pp bags or according to buyers preference
            </p>

            <p>
            We stock Tiger Nuts processed in hygienic conditions without chemical preservatives in large quantity. Our backward integration with farmers, interactions with stakeholders in the value chain ensure that all food safety standards are maintained. We subject to regulatory checks.

            </p>
            
            </div>
        </div>

        <div className='propro'>
            
            <img id='onefr' src={bauiwe} alt="" />
            <div className='fourhun'>
            <h3  className='txt'>Shea Butter/Nut</h3>
            <p>
            <strong>SPECIFICATIONS:</strong>  <br />
            <strong>LOW FATTY ACID: </strong> (&lt;0.75%), Zero <strong>IMPURITIES:</strong>  &lt;0.1% <br />
             <strong>MOISTURE CONTENT:</strong>  Very high concentration of vitamin A and Sterol High-quality consistency, low concentration of Oleic Acid, low peroxide value packed in 25kg or as required by client.  <br />                      <strong>ORIGIN:</strong>     Nigeria   <br />
             <strong>PACKAGING:</strong>  Packed in polythene lined boxes or aluminium, steel container that do not interfere with product fidelity. We label appropriately and with ideal details. Storage is recommended and conditions meet safety standards.
           </p>
           
            </div>
        </div>
          
        <div className='proop'>
            
            <img id='onefr' src={tawas} alt="" />
            <div className='fourhun'>
            <h3  className='txt'>Soy Beans</h3>
            <p>
            We process and export Soybeans that are commercially clean and contain 0.2% or less by weight of pods, coarse vegetable matter, stem, and foreign matters. 0.1% less of materials other than whole or broken soybeans. They are non-GMO. Free from poisonous seeds and husks. Lack musky smell or foreign odour.
            Our soybeans are of high quality with no heating issues. We stock with 14% oil with relative protein content.
           </p>

            
            </div>
        </div>


        <div className='propro'>
            
            <img id='onefr' src={tara} alt="" />
            <div className='fourhun'>
            <h3  className='txt'>Orange</h3>
            <p>
            Pests have not harmed our oranges in any way. There are no pest-affected appearance, rots, foreign odours, or tastes. There is no bruising, internal shrivelling, or any other abnormality caused by high or low temperatures.</p>
            
            <p>
            They are juicy and characteristic of their species. We prioritize packaging and deliver to locations without sacrificing quality. Chuksprime has a wide range of species and Class 1 oranges.


           </p>

            
            </div>
        </div>


        <div className='proop'>
            
            <img id='onefr' src={goma} alt="" />
            <div className='fourhun'>
            <h3  className='txt'>Red Kidney Beans</h3>
            <p>
            We provide a product from well-monitored farms, high-quality processing, food safety, integrity, and standards that are in line with global expectations.
            </p>

            <p>
            <strong>SPECIFICATIONS: </strong> <br />
            Undergoes phytosanitary control and is free of off-flavours and odours. Seed discoloration is less than 3%. Less than 1% seed defects, maximum moisture of 15%, no foreign matter Packed in 25/50kg PP bags or to the customer's specifications.
           </p>

            
            </div>
        </div>


        <div className='propro'>
            
            <img id='onefr' src={shadaya} alt="" />
            <div className='fourhun'>
            <h3  className='txt'>Pigeon Pea</h3>
            <p>
            Our company holds the capacity to supply high grades pigeon peas to meet diversified customer’s expectations in quantity and specifications. The products are much desired for their yummy taste, long lasting shelf time, non-GMO status, gluten-free, no artificial flavours and they are of adulterants. Purity is guaranteed at 99%, moisture of 10-12%, admixture of 2%.
            </p>
        </div>
        </div>


        <div className='proop'>
            
            <img id='onefr' src={shabui} alt="" />
            <div className='fourhun'>
            <h3  className='txt'>Fresh Garlic</h3>
            <p>
             <strong>ORIGIN:</strong>  Nigeria.
            </p>

            <p>
              We offer competitive and favourable payment terms for fresh garlic that are whole, strong textured, and plump-shaped-bulbs. Our fresh garlic is root-free, insect-free, no fungous stuff, no black moulds, not broken and without split on the skin. 
              </p>

              <p>
              Patronize us for healthy, spicy and neatly cleaned Garlic cloves. They come in sizes from 4.5cm to 6.5cm and the buyer’s requirement. We package for shipping to buyer’s requirements.
              </p>

        </div>
        </div>


        <div className='propro'>
            
            <img id='onefr' src={shauku} alt="" />
            <div className='fourhun'>
            <h3  className='txt'>Cassia Tora Seeds</h3>
            <p>
            We can supply large quantities of clean, more than 3mm sized Cassia Tora Seeds to various export destinations. Transact with flexible pricing and payment terms. Cassia Tora Seeds with a moisture content of less than 8%, impurities of less than 1%, absence of e-coli, no aflatoxin, no arsenic and are 99% whole.
            </p>

            <p>
          
            We process to less than 1% ash content and with a max of 2% admixture. We can package in PP and Jute bags to various kilograms to buyer’s request. 
            </p>


          </div>
      </div>


      <div className='proop'>
            
            <img id='onefr' src={shahudu} alt="" />
            <div className='fourhun'>
            <h3  className='txt'>Dried Moringa Leaves</h3>
            <p>
            <strong>SPECIFICATIONS:</strong>  <br />
            <strong>ORIGIN:</strong> Nigeria   <br />  
            <strong>STYLE:</strong> Dried      <br />
            <strong>COLOUR:</strong> Natural Green 
         
            </p>

            <p>
          
            100% organic, Timely supply, No foreign matter, Dried slowly at low temperature to retain delicate nutrients, Packed in vacuum bags, drums and to customer’s request.
            </p>


          </div>
      </div>


      <div className='propro'>
            
            <img id='onefr' src={shabiya} alt="" />
            <div className='fourhun'>
            <h3  className='txt'>Turmeric</h3>
            <p>

            <strong>SPECIFICATIONS:</strong> 
              <div className='grid'> <div><strong>ORIGIN:</strong> Nigeria </div>                            <div><strong>TYPE:</strong> Dry and Fresh <br /></div></div>
              <div className='grid'> <div><strong>MOISTURE:</strong> 10% </div>                            <div><strong>FLEXIBILITY:</strong> Hard <br /></div></div>
              <div className='grid'> <div><strong>CHROMATE TEST:</strong> Negative</div>        <div><strong>IMPURITIES:</strong> Less than 2%, <br /></div></div>
              <div className='grid'> <div><strong>BROKEN:</strong> Less than 5%  </div>               <div> <strong>DEFECTIVE:</strong> less than 3% <br /></div></div>
              <strong>PACKAGING:</strong> In 25/50/60kg in Jute bags or PPB. <br /> 
            </p>

            <p>
          
            All specifications are to buyer’s demand.
            </p>


          </div>
      </div>

      


         </div>



      </div>
    </div>
  )
}

export default OurProduct
